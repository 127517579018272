import '../styles/globals.css';
import type {AppProps} from 'next/app';
import {ApolloProvider} from '@apollo/client';
import {useRouter} from 'next/router';

import {client} from '../utils/queries';
import {getPlatformContextValue, PlatformContext, Platform} from '../platform/context';

function Starfish({Component, pageProps}: AppProps) {
  const router = useRouter();

  const platform = (router.query.platformName as string)?.toUpperCase() as Platform;

  return (
    <PlatformContext.Provider value={getPlatformContextValue(platform)}>
      <ApolloProvider client={client}>
        <Component {...pageProps} />
      </ApolloProvider>
    </PlatformContext.Provider>
  );
}

export default Starfish;
