import ColorBadge, {ColorBadgeSize} from '../components/ColorBadge';
import Condition from '../components/filters/options/Condition';

import {ControlType, createFilter, EMPTY_DEFAULT_IDENTIFIER, FilterType} from './utils';

export const createMemoryTermsFilter = ({featured = false} = {}) => createFilter({
  identifier: 'memory',
  label: 'Geheugen',
  controlType: ControlType.Checkbox,
  append: ' GB',
  gridCols: 3,
  featured,
});

export const createReleaseYearTermsFilter = () => createFilter({
  identifier: 'releaseYear',
  label: 'Releasejaar',
  controlType: ControlType.Checkbox,
  gridCols: 2,
});

export const createColorTermsFilter = ({featured = false} = {}) => createFilter({
  identifier: 'colorName',
  label: 'Kleur',
  controlType: ControlType.Checkbox,
  gridCols: 3,
  Option: ({option, compact}) =>
    <div className="sf-flex sf-flex-col sf-justify-center sf-content-center sf-items-center sf-cursor-pointer">
      <ColorBadge rgb={option.secondaryValue as string} size={(compact && ColorBadgeSize.Medium) || ColorBadgeSize.Large} title={option.value as string} />
      {!compact && <span style={{fontSize: '0.55rem', marginTop: '8px'}} className="sf-leading-none sf-mt-2 sf-pt-2">{option.value}</span>}
    </div>,
  featured,
});

export const createBodyColorTermsFilter = ({featured = false} = {}) => createFilter({
  identifier: 'bodyColorName',
  label: 'Kleur behuizing',
  controlType: ControlType.Checkbox,
  gridCols: 3,
  Option: ({option, compact}) =>
    <div className="sf-flex sf-flex-col sf-justify-center sf-content-center sf-items-center sf-cursor-pointer" title={option.value as string}>
      <ColorBadge rgb={option.secondaryValue as string} size={(compact && ColorBadgeSize.Medium) || ColorBadgeSize.Large} title={option.value as string} />
      {!compact && <span style={{fontSize: '0.55rem', marginTop: '8px'}} className="sf-leading-none sf-mt-2 sf-pt-2">{option.value}</span>}
    </div>,
  featured,
});

export const createDisplaySizeTermsFilter = ({featured = false} = {}) => createFilter({
  identifier: 'displaySize',
  label: 'Schermgrootte',
  controlType: ControlType.Checkbox,
  gridCols: 3,
  featured,
});

export const createMobileDataTermsFilter = () => createFilter({
  identifier: 'mobileData',
  label: 'Mobiel internet',
  controlType: ControlType.Radio,
  Option: ({option}) => <>{(Boolean(option.value) && 'Ja') || 'Nee'}</>,
  gridCols: 2,
});

export const createProcessorChipsetTermsFilter = () => createFilter({
  identifier: 'processorChipset',
  label: 'Processor',
  controlType: ControlType.Checkbox,
  gridCols: 1,
});

export const createRefurbishedTermsFilter = () => createFilter({
  identifier: 'refurbished',
  label: 'Conditie',
  controlType: ControlType.Checkbox,
  Option: ({option}) => <>{(option.value && 'Refurbished') || 'Nieuw'}</>,
  gridCols: 2,
});

export const createConditionTermsFilter = () => createFilter({
  identifier: 'condition',
  label: 'Refurbished',
  controlType: ControlType.Checkbox,
  Option: Condition,
  gridCols: 1,
});

export const createQualityMarkTermsFilter = () => createFilter({
  identifier: 'qualityMark',
  label: 'Keurmerk refurbished',
  controlType: ControlType.Checkbox,
  minOptions: 1,
  gridCols: 1,
});

export const createWarrantyTermsFilter = () => createFilter({
  identifier: 'warranty',
  label: 'Garantie',
  controlType: ControlType.Checkbox,
  append: ' maanden',
  gridCols: 1,
});

export const createDeliveryTimeRangeTermsFilter = () => createFilter({
  identifier: 'deliveryTimeRange',
  label: 'Levertijd',
  gridCols: 1,
  Option: ({option}) => {
    /* eslint-disable @typescript-eslint/naming-convention */
    const deliveryTimeRange = {
      1: '24 uur',
      2: '2 - 3 dagen',
      3: '4 - 5 dagen',
      4: '6 - 7 dagen',
    }[option.value as number] || '7+ dagen';
    /* eslint-enable @typescript-eslint/naming-convention */
    return <>{deliveryTimeRange}</>;
  },
  controlType: ControlType.Radio,
  filterType: FilterType.RangeMax,
});

export const createPlanPriceFilter = () => createFilter({
  identifier: 'planPrice',
  label: 'Maximale maandprijs',
  controlType: ControlType.Radio,
  gridCols: 3,
  filterType: FilterType.RangeMax,
  options: [
    {label: '€ 10,-', value: 10},
    {label: '€ 20,-', value: 20},
    {label: '€ 30,-', value: 30},
    {label: '€ 40,-', value: 40},
    {label: '€ 50,-', value: 50},
    {label: '€ 60,-', value: 60},
    {label: '€ 75,-', value: 75},
    {label: 'Maakt niet uit', value: 999999},
  ],
});

export const createProductPriceFilter = () => createFilter({
  identifier: 'productPrice',
  label: 'Toestel bijbetaling',
  controlType: ControlType.Radio,
  gridCols: 3,
  filterType: FilterType.RangeMax,
  options: [
    {label: '€ 0,-', value: 0},
    {label: '€ 100,-', value: 100},
    {label: '€ 200,-', value: 200},
    {label: '€ 300,-', value: 300},
    {label: '€ 500,-', value: 500},
    {label: '€ 600,-', value: 600},
    {label: '€ 750,-', value: 750},
    {label: '€ 1000,-', value: 1000},
    {label: 'Maakt niet uit', value: 999999},
  ],
});

export const createDataLimitFilter = () => createFilter({
  identifier: 'planDataLimit',
  label: 'Data',
  controlType: ControlType.Radio,
  gridCols: 3,
  filterType: FilterType.RangeMin,
  options: [
    {label: 'Geen', value: 0},
    {label: '500 MB', value: 500},
    {label: '1 GB', value: 1000},
    {label: '2 GB', value: 2000},
    {label: '5 GB', value: 5000},
    {label: '10 GB', value: 10000},
    {label: '15 GB', value: 15000},
    {label: 'Onbeperkt', value: 999999},
  ],
});

export const createCallLimitFilter = () => createFilter({
  identifier: 'planCallLimit',
  label: 'Minuten',
  controlType: ControlType.Radio,
  gridCols: 3,
  filterType: FilterType.RangeMin,
  options: [
    {label: 'Geen', value: 0},
    {label: '50 min', value: 50},
    {label: '100 min', value: 100},
    {label: '200 min', value: 200},
    {label: '300 min', value: 300},
    {label: 'Onbeperkt', value: 999999},
  ],
});

export const createDownloadSpeedFilter = () => createFilter({
  identifier: 'planDownloadSpeed',
  label: 'Download snelheid',
  controlType: ControlType.Radio,
  gridCols: 3,
  filterType: FilterType.RangeMin,
  options: [
    {label: '10 Mbit/s', value: 10.0},
    {label: '50 Mbit/s', value: 50.0},
    {label: '300 Mbit/s', value: 300.0},
    {label: '600 Mbit/s', value: 600.0},
    {label: '1 Gbit/s', value: 1000.0},
    {label: 'Maakt niet uit', value: 0.0},
  ],
});

export const createPlanNetworkGenerationFilter = () => createFilter({
  identifier: 'planNetworkGeneration',
  label: 'Mobiel internet',
  controlType: ControlType.Radio,
  gridCols: 2,
});

export const createBKRFilter = () => createFilter({
  identifier: 'withoutBkr',
  label: 'Zonder BKR',
  controlType: ControlType.Radio,
  Description: () =>
    <div className="sf-block">
      Toon alleen abonnementen met een telefoonkrediet van maximaal 250 euro. Boven dit bedrag dien je een inkomenstoets in te vullen en wordt de lening geregistreerd bij het BKR.
      {' '}<a className="sf-text-primary" href="/tips/telefoonkrediet/">Meer info</a>
    </div>,
  onlyShowFilterIfHasTerms: true,
  options: [
    {value: true, label: 'Zonder BKR'},
    {value: EMPTY_DEFAULT_IDENTIFIER, label: 'Maakt niet uit'},
  ],
  gridCols: 2,
  minOptions: 1,
});

export const createTelcoFilter = () => createFilter({
  identifier: 'telcoName',
  label: 'Provider',
  controlType: ControlType.Checkbox,
  gridCols: 3,
});

export const createPlanRenewalFilter = () => createFilter({
  identifier: 'planRenewal',
  label: 'Abonnement verlengen',
  controlType: ControlType.Radio,
  gridCols: 2,
  Option: ({option}) => <>{(option.value && 'Verlengen') || 'Nieuw afsluiten'}</>,
});

export const createTelcoEsimFilter = () => createFilter({
  identifier: 'telcoEsim',
  label: 'eSIM ondersteuning',
  controlType: ControlType.Radio,
  gridCols: 2,
  onlyShowFilterIfHasTerms: true,
  options: [
    {value: true, label: 'Ja'},
    {value: EMPTY_DEFAULT_IDENTIFIER, label: 'Maakt niet uit'},
  ],
});

export const createContractDurationFilter = () => createFilter({
  identifier: 'planContractDuration',
  label: 'Looptijd',
  append: ' mnd',
  controlType: ControlType.Checkbox,
  gridCols: 3,
});

export const createPackageDiscountFilter = () => createFilter({
  identifier: 'packageDiscountName',
  label: 'Combinatievoordeel',
  controlType: ControlType.Checkbox,
  gridCols: 2,
});
