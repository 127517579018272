/* eslint-disable no-process-env */
module.exports = {
  env: {
    SENTRY_DSN: process.env.SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    ORCA_URL: process.env.ORCA_URL,
    ORCA_PRIVATE_URL: process.env.ORCA_PRIVATE_URL,
    ORCA_PRIVATE_TOKEN: process.env.ORCA_PRIVATE_TOKEN,
    NEXT_PUBLIC_IMAGES_URL: process.env.NEXT_PUBLIC_IMAGES_URL,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
  },
};
