import {PackageFacets, PlanFacets, ProductFacets} from '../generated/graphql';

export const EMPTY_DEFAULT_IDENTIFIER = 'EMPTY_DEFAULT';

export enum ControlType {
  Checkbox = 'checkbox',
  Radio = 'radio',
}

export enum FilterType {
  In = 'In',
  Range = 'Range',
  RangeMin = 'RangeMin',
  RangeMax = 'RangeMax',
}

export interface AdvisorConfig {
  category: string;
  filters: BaseFilter[];
}

export interface FilterOption {
  label: string;
  value: number | string | boolean;
  secondaryValue?: number | string;
  url?: string;
}

export interface OptionProps {
  option: FilterOption;
  compact: boolean;
}

interface CreateFilterInput {
  identifier: string;
  label: string;
  controlType: ControlType;
  Description?: React.FunctionComponent | string;
  filterType?: FilterType;
  append?: string;
  Option?: React.FunctionComponent<OptionProps>;
  gridCols: number;
  minOptions?: number;
  onlyShowFilterIfHasTerms?: boolean;
  options?: FilterOption[];
  featured?: boolean;
}

export interface BaseFilter {
  identifier: string;
  label: string;
  controlType: ControlType;
  Description?: React.FunctionComponent | string;
  filterType: FilterType;
  append: string;
  Option?: React.FunctionComponent<OptionProps>;
  options?: FilterOption[];
  nullableOptions?: boolean;
  gridCols: number;
  minOptions: number;
  onlyShowFilterIfHasTerms: boolean;
  featured?: boolean;
}

export const createFilter = ({
  identifier,
  label,
  controlType,
  Description,
  filterType = FilterType.In,
  append = '',
  Option,
  gridCols,
  minOptions = 2,
  options = undefined,
  onlyShowFilterIfHasTerms = false,
  featured,
}: CreateFilterInput): BaseFilter => ({
  identifier,
  label,
  controlType: featured ? ControlType.Radio : controlType,
  Description,
  filterType,
  Option,
  append,
  gridCols,
  minOptions,
  options,
  onlyShowFilterIfHasTerms,
  nullableOptions: Boolean(featured),
  featured,
});

type Facets = ProductFacets | PlanFacets | PackageFacets;

export const facetsToFilters = (config: AdvisorConfig, facets: Facets) => {
  return config.filters
    .map((filter) => {
      if (filter.options && !filter.onlyShowFilterIfHasTerms) {
        return filter;
      }
      const options = (facets as any[])[`${filter.identifier}Terms` as any] || [];
      if (filter.onlyShowFilterIfHasTerms) {
        return options.length > 1 ? filter : {...filter, options: []};
      }
      return {
        ...filter,
        options: options
          .map((option: any) => ({
            ...option,
            label: createFilterOptionLabel(option.value, filter.append),
          })),
      };
    }).filter((filter) => {
      return filter.options && filter.options.length > 0;
    })
    .reduce((prev, cur) => ({...prev, [cur.identifier]: cur}), {});
};

const createFilterOptionLabel = (value: string | number, append: string) => `${value}${append || ''}`;
