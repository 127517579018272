import {OptionProps} from '../../../advisor-config/utils';
import conditionLabel from '../../../utils/conditionLabel';

export default function Condition({option: {value}}: OptionProps) {
  return (
    <span className="sf-text-center">
      {value > 0 &&
        <span
          title="Refurbished"
          className="sf-w-fit sf-leading-none sf-mb-2 sf-py-1 sf-px-2 sf-mr-2 sf-rounded-full sf-bg-refurbished sf-text-white sf-text-xs sf-font-bold sf-text-center"
        >R</span>
      }
      {conditionLabel(value as string)}
    </span>
  );
}
