import {createContext} from 'react';

export const PlatformContext = createContext<PlatformContextType>({
  imagesHostname: '',
});

export const getPlatformContextValue = (platform: Platform) =>
  platformContextValues[platform || 'default'];

export enum Platform {
  IPHONED = 'IPHONED',
  ANDROIDPLANET = 'ANDROIDPLANET',
  ANDROIDWORLDNL = 'ANDROIDWORLDNL',
}

const platformContextValues: any = {
  [Platform.IPHONED]: {
    imagesHostname: 'https://static.iphoned.nl',
  },
  [Platform.ANDROIDPLANET]: {
    imagesHostname: 'https://static.androidplanet.nl',
  },
  [Platform.ANDROIDWORLDNL]: {
    imagesHostname: 'https://static.androidworld.nl',
  },
  default: {
    imagesHostname: '',
  },
};

interface PlatformContextType {
  imagesHostname: string;
}
