/* eslint-disable @typescript-eslint/naming-convention */
const conditionLabel = (condition: string | number) => ({
  0: 'Nieuw',
  1: 'Zo goed als nieuw',
  2: 'Lichte gebruikerssporen',
  3: 'Zichtbaar gebruikt',
}[condition]);
/* eslint-enable @typescript-eslint/naming-convention */

export default conditionLabel;
