export enum ColorBadgeSize {
  Small,
  Medium,
  Large,
}

interface ColorBadgeProps {
  rgb: string;
  size?: ColorBadgeSize;
  title: string;
  className?: string;
}

export default function ColorBadge({rgb, size = ColorBadgeSize.Small, title, className = ''}: ColorBadgeProps) {
  const badgeSize = {
    [ColorBadgeSize.Small]: 'sf-w-3 sf-h-3',
    [ColorBadgeSize.Medium]: 'sf-w-5 sf-h-5',
    [ColorBadgeSize.Large]: 'sf-w-8 sf-h-8',
  }[size];

  return (
    <div
      className={`sf-rounded-full sf-inline-block ${badgeSize} ${className} sf-shadow-[inset_0_0_.2em_0_rgb(0,0,0,0.3)]`}
      style={{backgroundImage: `linear-gradient(to top, ${rgb}, ${rgb}99)`}}
      title={title}
    />
  );
}
